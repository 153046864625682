import React from "react"
import styled from "styled-components"
import SmallP from "../fonts/smallp"
import { graphql, StaticQuery } from "gatsby"

const NumberBannerSection = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiBandeauChiffre {
              firstText
              secondText
              firstNumber
              secondNumber
              label
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiBandeauChiffre
            : null
          return (
            <>
              <NumberBannerContainer>
                <NumberBannerContent>
                  <Bloc>
                    <Number>
                      {sectionDatas && sectionDatas.firstNumber} %<span>*</span>
                    </Number>
                    <TextContainer>
                      <SmallP color="#FFF">
                        {sectionDatas && sectionDatas.firstText}
                      </SmallP>
                    </TextContainer>
                  </Bloc>
                  <MobileSpacer />
                  <Bloc reverse>
                    <Number>
                      {sectionDatas && sectionDatas.secondNumber} %
                      <span>*</span>
                    </Number>
                    <TextContainer>
                      <SmallP color="#FFF">
                        {sectionDatas && sectionDatas.secondText}
                      </SmallP>
                    </TextContainer>
                  </Bloc>
                </NumberBannerContent>
              </NumberBannerContainer>
              <Legend>{sectionDatas && sectionDatas.label}</Legend>
            </>
          )
        }}
      />
    </>
  )
}

const Number = styled.span`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  width: 30%;

  span {
    font-size: 14px;
    position: relative;
    top: -30px;
    margin: 0 5px;
  }
  @media only screen and (max-width: 768px) {
    width: 34%;
  }
`

const Bloc = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
    justify-content: space-between;
  }
`
const MobileSpacer = styled.div`
  @media only screen and (max-width: 768px) {
    height: 45px;
  }
`

const Legend = styled.p`
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #000000;
  padding-right: 5%;
  padding-top: 5px;
`

const NumberBannerContainer = styled.div`
  height: 100%;
  padding: 30px 10%;
  background: #ff034d;
  @media only screen and (max-width: 768px) {
    padding: 30px 5%;
  }
`
const TextContainer = styled.div`
  width: 70%;
  @media only screen and (max-width: 768px) {
    width: 68%;
  }
`

const NumberBannerContent = styled.div`
  position: relative;
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export default NumberBannerSection
