import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useScrollYPosition } from "react-use-scroll-position"
import { Link } from "react-scroll"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import linkedinLogo from "../../images/linkedinlogo.png"
import facebookLogo from "../../images/facebooklogo.png"

import SmallForm from "../smallform"
import P from "../fonts/p"
import SmallP from "../fonts/smallp"
import Button from "../button"
import { Cta } from "../cta"

const FixedHeaderSection = ({ recaptchaRef, recaptcha }) => {
  const image = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [countDownDays, setCountDownDays] = useState("")
  const [countDownHours, setCountDownHours] = useState("")
  const [countDownMinutes, setCountDownMinutes] = useState("")
  const [isFixed, setIsFixed] = useState(false)
  const [curentUrl, setCurentUrl] = useState(false)

  const countDownDate = new Date("Jun 1, 2021 23:59:59").getTime()

  const countDownInterval = setInterval(function() {
    let now = new Date().getTime()

    let distance = countDownDate - now

    let days = Math.floor(distance / (1000 * 60 * 60 * 24))
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))

    setCountDownDays(days + "J ")
    setCountDownHours(hours + "H ")
    setCountDownMinutes(minutes + "M")

    if (distance < 0) {
      clearInterval(countDownInterval)
    }
  }, 1000)

  const scrollY = useScrollYPosition()

  useEffect(() => {
    if (scrollY > 85) {
      setIsFixed(true)
    } else {
      setIsFixed(false)
    }
  }, [scrollY])

  useEffect(() => {
    setCurentUrl(window.location.href)
  }, [])

  return (
    <>
      <FixedBar fixed={isFixed}>
        <Left>
          <a
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?u=${curentUrl}`}
            rel="noopener noreferrer"
          >
            <SocialImage
              src={facebookLogo}
              alt="Lien partage de l'appel à projets sur facebook (nouvel onglet)"
            />
          </a>
          <SocialImageSpace />
          <a
            target="_blank"
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${curentUrl}&title=&summary=&source=`}
            rel="noopener noreferrer"
          >
            <SocialImage
              src={linkedinLogo}
              alt="Lien partage de l'appel à projets sur linkedin (nouvel onglet)"
            />
          </a>
          <SocialImageSpace />
          <SmallP color="#5C239B">Partagez la page</SmallP>
        </Left>
        <Middle>
          {/* <P>
            <P color="#5C239B" weight="500" align="center">
              Début de l’appel à projets 2021 dans &nbsp;
              <span>
                <b>{countDownDays}</b>
              </span>
              <b>{countDownHours}</b>
              <span>
                <b>{countDownMinutes}</b>
              </span>
              Inscris-toi avant le 18 janvier 2021
            </P>
            <P color="#5C239B"></P>
          </P> */}
          <Img fixed={image.placeholderImage.childImageSharp.fixed} />
        </Middle>
        <Right>
          {/* <SmallForm
            recaptchaRef={recaptchaRef}
            recaptcha={recaptcha}
            submitText="Je pré-candidate"
            inputColor="#F5F5F5"
          /> */}
          <a
            href="https://liveforgood282120.typeform.com/to/TbAh8waY"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Cta>Je veux un mentor</Cta>
          </a>
        </Right>
        <RightMobile>
          {/* <Link
            activeClass="active"
            to="lastCall"
            spy={true}
            smooth={true}
            duration={250}
          >
            <Button rounded backgroundColor="#27AE60">
              Je pré-candidate
            </Button>
          </Link> */}
          <a
            href="https://liveforgood282120.typeform.com/to/TbAh8waY"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Cta>Je veux un mentor</Cta>
          </a>
        </RightMobile>
      </FixedBar>
    </>
  )
}

const FixedBar = styled.div`
  -webkit-backface-visibility: hidden;
  height: 50px;
  position: ${({ fixed }) => (fixed ? "fixed" : "relative")};
  z-index: 20;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 55px;
  height: 70px;
  bottom: 0;
  left: 0;
  @media only screen and (max-width: 768px) {
    position: fixed;
    height: 70px;
    bottom: 0;
    padding: 0 20px;
    justify-content: flex-start;
  }
`

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20.7%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const Middle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    width: auto;
    p {
      font-size: 14px;
      text-align: left;
    }
  }
`
const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39.3%;
  input[type="email"] {
    width: 70%;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const RightMobile = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 58%;
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: flex-start;
  }
`
const SocialImage = styled.img`
  width: 36px;
  height: 36px;
`
const SocialImageSpace = styled.div`
  width: 10px;
`

export default FixedHeaderSection
