import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import { useScrollYPosition } from "react-use-scroll-position"
import { graphql, StaticQuery } from "gatsby"
import { Element } from "react-scroll"

import whiteDisk from "../../images/whitedisk.png"
import elem1 from "../../images/jointuselem1.png"
import elem2 from "../../images/jointuselem2.png"
import elem3 from "../../images/jointuselem3.png"
import checkImg from "../../images/check.png"
import P from "../fonts/p"
import H3 from "../fonts/h3"
import SmallForm from "../smallform"
import Li from "../fonts/li"
import SmallP from "../fonts/smallp"
import { Cta } from "../cta"

import linkedinLogo from "../../images/linkedinlogo.png"
import instagramLogo from "../../images/instagramlogo.png"
import facebookLogo from "../../images/facebooklogo.png"
import liveforgoodLogo from "../../images/favicon_reverse.png"

const LastCallSection = ({ retrieveDatas, recaptchaRef, recaptcha }) => {
  let sectionRef = useRef()
  const [animmated, setAnimmated] = useState(false)
  const [hideOverlay, setHideOverlay] = useState(true)

  const scrollY = useScrollYPosition()

  useEffect(() => {
    if (
      scrollY > sectionRef.current.offsetTop - 500 &&
      scrollY <
        sectionRef.current.offsetTop -
          window.innerHeight +
          sectionRef.current.offsetHeight +
          50
    ) {
      setAnimmated(true)
    } else if (
      scrollY >
        sectionRef.current.offsetTop -
          window.innerHeight +
          sectionRef.current.offsetHeight * 2 ||
      scrollY < sectionRef.current.offsetTop - window.innerHeight
    ) {
      setHideOverlay(true)
    } else {
      setHideOverlay(false)
    }
  }, [scrollY])

  return (
    <StaticQuery
      query={graphql`
        query {
          strapiLastCall {
            id
            titre
            description
            bulle1titre
            bulle1date
            bulle2texte1
            bulle2texte2
            bulle2texte3
            bulle2texte4
            bulle2texte5
            Description2
            CtaURL
            CtaText
          }
          strapiLivreBlanc {
            facebook
            instagram
            linkedIn
            liveforgood
          }
        }
      `}
      render={retrieveDatas => {
        const sectionDatas = retrieveDatas ? retrieveDatas.strapiLastCall : null
        const socialSectionDatas = retrieveDatas
          ? retrieveDatas.strapiLivreBlanc
          : null
        return (
          <Global ref={sectionRef}>
            <Element name="lastCall" />
            <Controller>
              <Scene indicators={false} duration="80%" triggerHook="onEnter">
                <Timeline wrapper={<div className="parallax" />}>
                  <Tween
                    from={{
                      scale1: "0.8",
                      opacity: "0",
                    }}
                    to={{
                      scale: "1",
                      opacity: "1",
                    }}
                  >
                    <LastCallContainer>
                      <LastCallContent>
                        <Controller>
                          <Scene
                            indicators={false}
                            duration="20%"
                            triggerHook="onCenter"
                          >
                            <Timeline wrapper={<div className="parallax" />}>
                              <Tween
                                from={{
                                  opacity: "1",
                                }}
                                to={{
                                  opacity: "0",
                                }}
                              >
                                <ElemParallax1
                                  src={elem1}
                                  alt="Element graphique non essentiel"
                                />
                                <ElemParallax2
                                  src={elem2}
                                  alt="Element graphique non essentiel"
                                />
                                <ElemParallax3
                                  src={elem3}
                                  alt="Element graphique non essentiel"
                                />
                              </Tween>
                            </Timeline>
                          </Scene>
                        </Controller>
                        <Content>
                          <LeftSection>
                            <CheckImage
                              src={checkImg}
                              alt="Element graphique non essentiel"
                            />
                            <Spacer />
                            <P color="#fff" align="center">
                              {sectionDatas && sectionDatas.bulle1titre}
                            </P>
                            <H3 color="#fff" align="center">
                              {sectionDatas && sectionDatas.bulle1date}
                            </H3>
                          </LeftSection>
                          <MidSection>
                            <H3 color="#fff" align="center">
                              {sectionDatas && sectionDatas.titre}
                            </H3>
                            <H3 color="#fff" align="center">
                              {sectionDatas && sectionDatas.titre2}
                            </H3>
                            <Spacer />
                            <MiddleTextContainer>
                              <P
                                color="#fff"
                                align="center"
                                weight="400"
                                lineHeight="27px"
                              >
                                {sectionDatas && sectionDatas.description}
                              </P>
                            </MiddleTextContainer>
                            <Spacer />
                            {/* <SmallForm
                              recaptchaRef={recaptchaRef}
                              recaptcha={recaptcha}
                            /> */}
                            <a
                              href="https://liveforgood282120.typeform.com/to/TbAh8waY"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Cta>Je veux un mentor</Cta>
                            </a>
                            {/* <Spacer />
                            <MiddleTextContainer>
                              <P
                                color="#fff"
                                align="center"
                                weight="400"
                                lineHeight="27px"
                              >
                                {sectionDatas && sectionDatas.Description2}
                              </P>
                            </MiddleTextContainer>
                            <Spacer />
                            <a
                              href={sectionDatas && sectionDatas.CtaURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Cta>{sectionDatas && sectionDatas.CtaText}</Cta>
                            </a> */}
                          </MidSection>
                          <RightSection background={whiteDisk}>
                            <Spacer />
                            <Li align="center">
                              {sectionDatas && sectionDatas.bulle2texte1}
                            </Li>
                            <Spacer />
                            <Li align="center">
                              {sectionDatas && sectionDatas.bulle2texte2}
                            </Li>
                            <Spacer />
                            <Li align="center">
                              {sectionDatas && sectionDatas.bulle2texte3}
                            </Li>
                            <SmallP weight="700" align="center">
                              {sectionDatas && sectionDatas.bulle2texte4}
                            </SmallP>
                            <Spacer />
                            {/* <Li align="center">
                              {sectionDatas && sectionDatas.bulle2texte5}
                            </Li> */}
                          </RightSection>
                        </Content>
                        <br />
                        <Questions>
                          <SmallP color="#fff" align="center">
                            Des questions ?
                          </SmallP>
                          <a href="mailto:candidatures@live-for-good.org">
                            <LinkUnderlined>
                              <SmallP color="#fff" align="center">
                                Contacte-nous
                              </SmallP>
                            </LinkUnderlined>
                          </a>
                          <br />
                          <SocialRow>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={
                                socialSectionDatas
                                  ? socialSectionDatas.facebook
                                  : "/"
                              }
                            >
                              <SocialImg
                                src={facebookLogo}
                                alt="Visiter notre page Facebook (nouvel onglet)"
                              />
                            </a>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={
                                socialSectionDatas
                                  ? socialSectionDatas.instagram
                                  : "/"
                              }
                            >
                              <SocialImg
                                src={instagramLogo}
                                alt="Visiter notre page Instagram (nouvel onglet)"
                              />
                            </a>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={
                                socialSectionDatas
                                  ? socialSectionDatas.linkedIn
                                  : "/"
                              }
                            >
                              <SocialImg
                                src={linkedinLogo}
                                alt="Visiter notre page LinkedIn (nouvel onglet)"
                              />
                            </a>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={
                                socialSectionDatas
                                  ? socialSectionDatas.liveforgood
                                  : "/"
                              }
                            >
                              <SocialImg
                                src={liveforgoodLogo}
                                alt="Visiter site officiel (nouvel onglet)"
                              />
                            </a>
                          </SocialRow>
                        </Questions>
                      </LastCallContent>

                      <LastCallContentMobile>
                        <Controller>
                          <Scene
                            indicators={false}
                            duration="20%"
                            triggerHook="onCenter"
                          >
                            <Timeline wrapper={<div className="parallax" />}>
                              <Tween
                                from={{
                                  opacity: "1",
                                }}
                                to={{
                                  opacity: "0",
                                }}
                              >
                                <ElemParallax1
                                  src={elem1}
                                  alt="Element graphique non essentiel"
                                />
                                <ElemParallax2
                                  src={elem2}
                                  alt="Element graphique non essentiel"
                                />
                                <ElemParallax3
                                  src={elem3}
                                  alt="Element graphique non essentiel"
                                />
                              </Tween>
                            </Timeline>
                          </Scene>
                        </Controller>
                        <Content>
                          <H3 color="#fff" align="center" mobileSize="23px">
                            {sectionDatas && sectionDatas.titre}
                          </H3>
                          <H3 color="#fff" align="center" mobileSize="23px">
                            {sectionDatas && sectionDatas.titre2}
                          </H3>
                          <Spacer />
                          <P weight="500" color="#fff">
                            {sectionDatas && sectionDatas.bulle1titre}{" "}
                            {sectionDatas && sectionDatas.bulle1date}
                          </P>
                          <RightSection>
                            <Li color="#fff" align="left">
                              {sectionDatas && sectionDatas.bulle2texte1}
                            </Li>

                            <Li color="#fff" align="left">
                              {sectionDatas && sectionDatas.bulle2texte2}
                            </Li>
                            <Li color="#fff" align="left">
                              {sectionDatas && sectionDatas.bulle2texte3}
                            </Li>
                            <Li color="#fff" align="left">
                              {sectionDatas && sectionDatas.bulle2texte4}
                            </Li>
                          </RightSection>
                          <SmallP
                            align="center"
                            color="#fff"
                            weight="500"
                            mobileSize="12px"
                          >
                            {sectionDatas && sectionDatas.description}
                          </SmallP>
                          <Spacer />
                          <FormContainer>
                            {/* <SmallForm
                              recaptchaRef={recaptchaRef}
                              recaptcha={recaptcha}
                              submitText="Je pré-candidate"
                            /> */}
                            <a
                              href="https://liveforgood282120.typeform.com/to/TbAh8waY"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Cta>Je veux un mentor</Cta>
                            </a>
                          </FormContainer>
                          {/* <SmallP
                            align="center"
                            color="#fff"
                            weight="500"
                            mobileSize="12px"
                          >
                            {sectionDatas && sectionDatas.Description2}
                          </SmallP>
                          <Spacer />
                          <FormContainer>
                            <a
                              href={sectionDatas && sectionDatas.CtaURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Cta>{sectionDatas && sectionDatas.CtaText}</Cta>
                            </a>
                          </FormContainer> */}
                        </Content>
                        <br />
                        <SmallP color="#fff" align="center">
                          Des questions ?
                        </SmallP>
                        <a href="mailto:candidatures@live-for-good.org">
                          <LinkUnderlined>
                            <SmallP color="#fff" align="center">
                              Contacte-nous
                            </SmallP>
                          </LinkUnderlined>
                        </a>
                        <br />
                        <SocialRow>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={
                              socialSectionDatas
                                ? socialSectionDatas.facebook
                                : "/"
                            }
                          >
                            <SocialImg
                              src={facebookLogo}
                              alt="Visiter notre page Facebook (nouvel onglet)"
                            />
                          </a>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={
                              socialSectionDatas
                                ? socialSectionDatas.instagram
                                : "/"
                            }
                          >
                            <SocialImg
                              src={instagramLogo}
                              alt="Visiter notre page Instagram (nouvel onglet)"
                            />
                          </a>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={
                              socialSectionDatas
                                ? socialSectionDatas.linkedIn
                                : "/"
                            }
                          >
                            <SocialImg
                              src={linkedinLogo}
                              alt="Visiter notre page LinkedIn (nouvel onglet)"
                            />
                          </a>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={
                              socialSectionDatas
                                ? socialSectionDatas.liveforgood
                                : "/"
                            }
                          >
                            <SocialImg
                              src={liveforgoodLogo}
                              alt="Visiter site officiel (nouvel onglet)"
                            />
                          </a>
                        </SocialRow>
                      </LastCallContentMobile>
                    </LastCallContainer>
                  </Tween>
                </Timeline>
              </Scene>
            </Controller>

            <Controller>
              <Scene indicators={false} duration="60%" triggerHook="onCenter">
                <Timeline wrapper={<div className="parallax" />}>
                  <Tween
                    from={{
                      opacity: 0,
                    }}
                    to={{
                      opacity: 1,
                    }}
                  >
                    <Overlay isAnimmated={animmated} hide={hideOverlay} />
                  </Tween>
                </Timeline>
              </Scene>
            </Controller>
          </Global>
        )
      }}
    />
  )
}

const Global = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  @media only screen and (max-width: 768px) {
    height: 120vh;
    margin-bottom: 50px;
    margin-top: 0;
  }
`

const ElemParallax1 = styled.img`
  position: absolute;
  top: -30px;
  left: -40px;
  z-index: 3;
`
const ElemParallax2 = styled.img`
  position: absolute;
  top: -60px;
  right: 0%;
  z-index: -1;
`
const ElemParallax3 = styled.img`
  position: absolute;
  bottom: -17px;
  left: 50%;
  z-index: -1;
`

const LastCallContainer = styled.div`
  width: 80%;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 1025px) {
    width: 95%;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`
const LastCallContent = styled.div`
  z-index: 2;
  position: relative;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const LastCallContentMobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    position: relative;
    z-index: 2;
  }
`

const Content = styled.div`
  background: #5c229b;
  border-radius: 17px;
  padding: 50px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 30px;
  }
`
const LeftSection = styled.div`
  width: 22.7%;
`
const MidSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 46.9%;
  padding: 0 2%;
  input[type="email"] {
    width: 70%;
  }
`
const MiddleTextContainer = styled.div`
  padding: 0 10%;
`

const RightSection = styled.div`
  background: ${({ background }) =>
    background ? `url(${background})no-repeat` : "#fff"};
  background-size: contain;
  background-position: center;
  border-radius: ${({ background }) => (background ? 0 : "500px")};
  padding: 54px 26px;
  width: 29.3%;
  * {
    font-size: 0.9vw;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    background: transparent;
    border-radius: 0;
    padding: 25px 15%;
    * {
      font-size: initial;
    }
  }
`

const CheckImage = styled.img`
  display: block;
  margin: 0 auto;
`

const Spacer = styled.div`
  height: 25px;
  @media only screen and (max-width: 1025px) {
    height: 5px;
  }
  @media only screen and (max-width: 768px) {
  }
`

const Overlay = styled.div`
  position: ${({ isAnimmated }) => (isAnimmated ? "fixed" : "absolute")};
  width: ${({ isAnimmated }) => (isAnimmated ? "100%" : "0")};
  height: ${({ hide }) => (hide ? "0" : "100%")};
  transition: 1s opacity;
  /* height: 100%; */
  top: 0;
  left: 0;
  background: rgba(92, 34, 155, 0.8);
`

const FormContainer = styled.div`
  padding: 40px 0 40px;
  width: 100%;
`

const LinkUnderlined = styled.div`
  text-decoration: underline;
  text-decoration-color: white;
`

const SocialRow = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 32%;
  @media only screen and (max-width: 768px) {
    padding: 0 25%;
  }
`
const SocialImg = styled.img`
  margin: 8px;
  width: 36px;
  height: 36px;
`

const Questions = styled.div`
  margin-right: 6%;
`
export default LastCallSection
