import React from "react"
import styled from "styled-components"

const H5 = ({ children, color, fontStyle, weight }) => (
  <Text color={color} fontstyle={fontStyle} weight={weight}>
    {children}
  </Text>
)

const Text = styled.h5`
  font-family: Poppins;
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  font-size: 20px;
  line-height: 27px;
  font-style: 18px;
  color: ${({ color }) => (color ? color : "#000")};
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`

export default H5
