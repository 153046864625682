import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useScrollYPosition } from "react-use-scroll-position"
import ReactMarkdown from "react-markdown"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "react-scroll"

import SmallP from "../fonts/smallp"
import H2 from "../fonts/h2"
import Button from "../button"
import ProjectCard from "../projectcard"

const AlumniProjetsSection = () => {
  let textContainerRef = useRef()
  let listRef = useRef()
  let sectionRef = useRef()
  const scrollY = useScrollYPosition()

  const [column1, setColumn1] = useState([])
  const [column2, setColumn2] = useState([])
  const [isFixed, setIsFixed] = useState(false)
  const [fixedPosition, setFixedPosition] = useState("top")

  useEffect(() => {
    if (
      scrollY >
      sectionRef.current.offsetTop +
        sectionRef.current.offsetHeight -
        (textContainerRef.current.offsetHeight + 100)
    ) {
      setIsFixed(false)
      setFixedPosition("bottom")
    } else if (scrollY > sectionRef.current.offsetTop) {
      setIsFixed(true)
      setFixedPosition("top")
    } else {
      setIsFixed(false)
      setFixedPosition("top")
    }
  }, [scrollY])
  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiProjetAlumni {
              titre
              description
              projet {
                imageCouverture {
                  childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                imageProfil {
                  childImageSharp {
                    fixed(width: 70, height: 70) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                nom
                nomDuProjet
                hashtag
              }
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiProjetAlumni
            : null
          if (column1.length === 0) {
            sectionDatas.projet.map((props, index) => {
              if (index % 2 === 0) {
                return setColumn1(oldArray => [...oldArray, props])
              } else {
                return setColumn2(oldArray => [...oldArray, props])
              }
            })
          }
          return (
            <AlumniProjetsContainer ref={sectionRef}>
              <AlumniProjetsContent>
                <Desktop>
                  <TextContainer
                    ref={textContainerRef}
                    isFixed={isFixed}
                    fixedPosition={fixedPosition}
                  >
                    <H2>
                      <ReactMarkdown
                        source={sectionDatas ? sectionDatas.titre : ""}
                      />
                    </H2>
                    <TextSeparator />
                    <SmallP weight="500">
                      <ReactMarkdown
                        source={sectionDatas ? sectionDatas.description : ""}
                      />
                    </SmallP>
                    <TextSeparator />
                    <TextSeparator />
                    {/* <Desktop>
                      <Link
                        activeClass="active"
                        to="lastCall"
                        spy={true}
                        smooth={true}
                        duration={250}
                      >
                        <Button
                          backgroundColor="#27AE60"
                          activeBackground="#209B54"
                          rounded
                          width="70%"
                        >
                          Rejoindre la promo
                        </Button>
                      </Link>
                    </Desktop> */}
                  </TextContainer>
                </Desktop>
                <Mobile>
                  <TextContainer>
                    <H2>
                      <ReactMarkdown
                        source={sectionDatas ? sectionDatas.titre : ""}
                      />
                    </H2>
                    <TextSeparator />
                    <SmallP weight="500">
                      <ReactMarkdown
                        source={sectionDatas && sectionDatas.description}
                      />
                    </SmallP>
                    <TextSeparator />
                    <TextSeparator />
                    {/* <Desktop>
                      <Link
                        activeClass="active"
                        to="lastCall"
                        spy={true}
                        smooth={true}
                        duration={250}
                      >
                        <Button
                          backgroundColor="#27AE60"
                          activeBackground="#209B54"
                          rounded
                          width="55%"
                        >
                          Rejoindre la promo
                        </Button>
                      </Link>
                    </Desktop> */}
                  </TextContainer>
                </Mobile>
                <ListContainer ref={listRef}>
                  <ProjectColumn marginRight>
                    {column1.map(
                      ({
                        id,
                        nom,
                        nomDuProjet,
                        hashtag,
                        imageCouverture,
                        imageProfil,
                      }) => (
                        <>
                          <ProjectCard
                            key={id}
                            profilImage={imageProfil.childImageSharp.fixed.src}
                            fluidGatsbyImage={
                              imageCouverture &&
                              imageCouverture.childImageSharp.fluid
                            }
                            title={nom}
                            projectName={nomDuProjet}
                            text={hashtag}
                          />
                          <ProjectSpacer />
                        </>
                      )
                    )}
                  </ProjectColumn>
                  <ProjectColumn overflow={"true"}>
                    {column2.map(
                      ({
                        id,
                        nom,
                        nomDuProjet,
                        hashtag,
                        imageCouverture,
                        imageProfil,
                      }) => (
                        <>
                          <ProjectCard
                            key={id}
                            profilImage={imageProfil.childImageSharp.fixed.src}
                            fluidGatsbyImage={
                              imageCouverture &&
                              imageCouverture.childImageSharp.fluid
                            }
                            title={nom}
                            projectName={nomDuProjet}
                            text={hashtag}
                          />
                          <ProjectSpacer />
                        </>
                      )
                    )}
                  </ProjectColumn>
                </ListContainer>
              </AlumniProjetsContent>
            </AlumniProjetsContainer>
          )
        }}
      />
    </>
  )
}

const AlumniProjetsContainer = styled.div`
  height: 100%;
  padding: 150px 8vw 0;
  position: relative;
  @media only screen and (max-width: 970px) {
    padding: 100px 8vw 0;
  }
`
const ProjectSpacer = styled.div`
  height: 15px;
  @media only screen and (max-width: 970px) {
    height: 50px;
  }
`
const ProjectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-top: ${({ overflow }) => (overflow ? "130px" : 0)};
  margin-right: ${({ marginRight }) => (marginRight ? "45px" : 0)};
  @media only screen and (max-width: 970px) {
    width: 100%;
    margin-right: 0;
    margin-top: 0;
  }
`

const AlumniProjetsContent = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  @media only screen and (max-width: 970px) {
    flex-direction: column;
  }
`

const ListContainer = styled.div`
  display: flex;
  margin-left: auto;
  @media only screen and (max-width: 970px) {
    flex-direction: column;
    width: 100%;
  }
`

const TextContainer = styled.div`
  position: ${({ isFixed }) => (isFixed ? "fixed" : "absolute")};
  top: ${({ fixedPosition }) =>
    fixedPosition === "top" ? "100px" : "initial"};
  bottom: ${({ fixedPosition }) =>
    fixedPosition === "bottom" ? "0" : "initial"};
  width: 100% !important;
  max-width: 34vw;
  transition: all 1s;
  @media only screen and (max-width: 1024px) {
    max-width: 28vw;
  }
  @media only screen and (min-width: 2559px) {
    max-width: 17vw;
  }
  @media only screen and (min-width: 970px) {
    max-width: 28vw;
  }
  @media only screen and (max-width: 970px) {
    width: 100% !important;
    max-width: 100%;
    position: relative;
    /* min-width: 100%; */
  }
`
const TextSeparator = styled.div`
  height: 12px;
`

const Desktop = styled.div`
  display: block;
  @media only screen and (max-width: 970px) {
    display: none;
  }
`

const Mobile = styled.div`
  display: none;
  @media only screen and (max-width: 970px) {
    display: block;
  }
`

export default AlumniProjetsSection
