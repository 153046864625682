import React, { useState, useEffect } from "react"
import styled from "styled-components"
import axios from "axios"
import PropTypes from "prop-types"

import P from "./fonts/p"

const websiteURL = process.env.GATSBY_WEBSITE_URL
const platformURL = process.env.GATSBY_PLATFORM_URL

const isValidEmail = email => /\S+@\S+\.\S+/.test(email)

const CustomForm = ({
  subscribe,
  submitText,
  ctaFrom,
  inputColor,
  tags,
  mandrillTemplate,
  subject,
  recaptchaRef,
  recaptcha,
}) => {
  const [status, setStatus] = useState("waiting")
  const [message, setMessage] = useState("Chargement...")
  const [email, setEmail] = useState("")
  const [isEmailSent, setIsEmailSent] = useState(false)

  useEffect(() => {
    if (
      recaptcha === true &&
      email !== "" &&
      isValidEmail(email) &&
      isEmailSent === false
    ) {
      const doc = {
        email: email,
        tags: tags,
        mandrillTemplate: mandrillTemplate,
        subject: subject,
        ctaFrom: ctaFrom,
      }

      axios.post(`${websiteURL}/.netlify/functions/mailchimp-subscribe`, doc)
      setStatus("success")
      if (ctaFrom === "candidate") {
        setMessage("Chargement...")
        setIsEmailSent(true)
        // window.location = `${platformURL}/apply?email=${email}`
        setMessage("Email enregistré, félicitation !")
      } else {
        setMessage("Rendez-vous sur votre messagerie !")
        setIsEmailSent(true)
      }
    }
  })

  const submit = () => {
    if (email && isValidEmail(email)) {
      recaptchaRef.current.execute()
    }
  }

  return (
    <Form>
      {
        <>
          {status === "success" ? (
            <MessageContainer>
              <P color={"#000"} align="center" weight="500">
                {message}
              </P>
            </MessageContainer>
          ) : (
            ""
          )}

          {status !== "success" && (
            <>
              <TextInput
                type="email"
                placeholder={
                  status === "error" ? "Email déja utilisé" : "Adresse mail"
                }
                inputColor={inputColor}
                color={status === "error" ? "#00C0EE" : "#000"}
                placeholderColor={status === "error" ? "#00C0EE" : "#505050"}
                onChange={value => setEmail(value.target.value)}
              />
              <SubmitInput onClick={submit}>
                {submitText ? submitText : "Je pré-candidate"}
              </SubmitInput>
            </>
          )}
        </>
      }
    </Form>
  )
}

const SmallForm = ({
  submitText,
  inputColor,
  ctaFrom,
  tags,
  mandrillTemplate,
  subject,
  recaptchaRef,
  recaptcha,
}) => {
  return (
    <FormContainer>
      <CustomForm
        submitText={submitText}
        inputColor={inputColor}
        tags={tags}
        mandrillTemplate={mandrillTemplate}
        subject={subject}
        ctaFrom={ctaFrom}
        recaptchaRef={recaptchaRef}
        recaptcha={recaptcha}
        // status={status}
        // message={message}
      />
    </FormContainer>
  )
}

SmallForm.propTypes = {
  submitText: PropTypes.string,
  inputColor: PropTypes.string,
  ctaFrom: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  mandrillTemplate: PropTypes.string,
  subject: PropTypes.string,
}

SmallForm.defaultProps = {
  ctaFrom: "candidate",
  tags: [
    "Entrepreneur for Good > Prospects 2021",
    "Entrepreneur for Good > Prospects 2021 > Landing page",
    "Entrepreneur for Good > Prospects 2021 > Landing page > Je pre-candidate CTA",
  ],
  mandrillTemplate: "a-p-pr-inscription-continue-pefg-2021",
  subject: "Pré-inscription au Programme Entrepreneur for Good 2020",
}

const FormContainer = styled.div``

const Form = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`
const TextInput = styled.input`
  width: 50%;
  left: 25px;
  position: relative;
  background: ${({ inputColor }) => (inputColor ? inputColor : "#fff")};
  outline: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3vw;
  line-height: 27px;
  color: ${({ color }) => color};
  border: none;
  border-radius: 82px;
  width: 100%;
  height: 55px;
  padding-right: 52px;
  padding-left: 20px;
  ::placeholder {
    color: ${({ placeholderColor }) => placeholderColor};
    opacity: 1;
  }
  @media only screen and (max-width: 768px) {
    text-align: center;
    padding: 0;
    left: 0;
    font-size: 18px;
  }
`
const SubmitInput = styled.button`
  position: relative;

  right: 25px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  color: #fff;
  background-color: #27ae60;
  border: none;
  border-radius: 82px;
  height: auto;
  padding: 0 25px;
  &:hover {
    cursor: pointer;
    background-color: #209b54;
  }
  min-width: 40%;
  width: auto;
  @media only screen and (max-width: 768px) {
    position: relative;
    height: 55px;
    margin-top: 10px;
    right: 0;
    width: 100%;
    font-size: 18px;
  }
`
const MessageContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 82px;
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
`
export default SmallForm
