import React, { useState } from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { graphql, StaticQuery } from "gatsby"

import needsBackgroundImg from "../../images/needsbackground.png"

import H2 from "../fonts/h2"
import SmallP from "../fonts/smallp"
import Button from "../button"

const PlateformeSection = ({ retrieveDatas }) => {
  const [column1, setColumn1] = useState([])
  const [column2, setColumn2] = useState([])
  const [currentCat, setCurrentCat] = useState(null)

  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiLaPlateforme {
              titre
              titreImage
              sousTitre
              plateformeArticles {
                id
                image {
                  childImageSharp {
                    fixed(width: 712, height: 308) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                titre
              }
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiLaPlateforme
            : null
          if (column1.length === 0) {
            sectionDatas.plateformeArticles.map((props, index) => {
              if (index % 2 === 0) {
                return setColumn1(oldArray => [...oldArray, props])
              } else {
                return setColumn2(oldArray => [...oldArray, props])
              }
            })
            setCurrentCat(sectionDatas.plateformeArticles[0])
          }
          return (
            <PlateformeContainer>
              <PlateformeBackground
                src={needsBackgroundImg}
                alt="Element graphique non essentiel"
              />

              <TitleContainer>
                <H2 mobileSize="25px">
                  <ReactMarkdown
                    source={sectionDatas ? sectionDatas.titre : ""}
                  />
                </H2>
                <SmallP weight="500">
                  {sectionDatas && sectionDatas.sousTitre}
                </SmallP>
              </TitleContainer>

              <PlateformeContent>
                <PlateformeBackgroundMobile
                  src={needsBackgroundImg}
                  alt="Element graphique non essentiel"
                />
                <LeftSection>
                  {column1.map(({ titre, id }) => (
                    <ButtonContainer key={id}>
                      <Button
                        backgroundColor="#fff"
                        shadow
                        fontColor="#000"
                        activeBackground="#00C0EE"
                        activeColor="#fff"
                        isActive={currentCat.id === id}
                        onPress={() =>
                          setCurrentCat(column1.find(x => x.id === id))
                        }
                      >
                        {titre}
                      </Button>
                    </ButtonContainer>
                  ))}
                </LeftSection>
                <MiddleSection>
                  <SmallP weight="500" align="center">
                    {sectionDatas && sectionDatas.titreImage}
                  </SmallP>
                  {currentCat && (
                    <CategoryImage
                      background={currentCat.image.childImageSharp.fixed.src}
                    />
                  )}
                </MiddleSection>
                <RightSection>
                  {column2.map(({ titre, id }) => (
                    <ButtonContainer key={id}>
                      <Button
                        backgroundColor="#fff"
                        shadow
                        fontColor="#000"
                        activeBackground="#00C0EE"
                        activeColor="#fff"
                        isActive={currentCat.id === id}
                        onPress={() =>
                          setCurrentCat(column2.find(x => x.id === id))
                        }
                      >
                        {titre}
                      </Button>
                    </ButtonContainer>
                  ))}
                </RightSection>
              </PlateformeContent>
            </PlateformeContainer>
          )
        }}
      />
    </>
  )
}

const CategoryImage = styled.div`
  margin-top: 12px;
  background: ${({ background }) =>
    background && `url(${background}) no-repeat`};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 70%;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.18);
  @media only screen and (max-width: 768px) {
    height: 100%;
  }
`

const PlateformeContainer = styled.div`
  height: auto;
  padding: 66px 8vw 143px;
  position: relative;
  margin-top: 200px;
  @media only screen and (max-width: 768px) {
    padding: 66px 8vw 0;
    margin-top: 0;
  }
`

const TitleContainer = styled.div`
  position: relative;
  bottom: 30px;
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const PlateformeContent = styled.div`
  padding: 50px 0;

  position: relative;
  display: flex;
  @media only screen and (max-width: 768px) {
    padding-top: 50vw;
    justify-content: space-between;
  }
`

const RightSection = styled.div`
  width: 20%;
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 50%;
    padding: 200px 0 0;
  }
`
const LeftSection = styled.div`
  height: 100%;
  width: 20%;
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 45%;
    padding: 200px 0 0;
  }
`
const MiddleSection = styled.div`
  padding: 0 5% 0 5%;
  width: 100%;
  position: relative;
  @media only screen and (max-width: 768px) {
    padding: 100px 0 0 0;
    width: 100%;
    position: absolute;
    top: 0;
    height: 45%;
  }
`

const PlateformeBackground = styled.img`
  position: absolute;
  max-width: 644px;
  top: 14px;
  left: 15%;
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const PlateformeBackgroundMobile = styled.img`
  display: none;
  @media only screen and (max-width: 768px) {
    position: absolute;
    max-width: 100%;
    top: 45px;
    left: 0%;
    width: 100%;
    z-index: -1;
    display: block;
  }
`

const ButtonContainer = styled.div`
  margin-bottom: 50px;
  width: 100%;
`

export default PlateformeSection
