import React from "react"
import styled from "styled-components"

const Button = ({
  children,
  fontColor,
  backgroundColor,
  activeColor,
  activeBackground,
  rounded,
  height,
  width,
  shadow,
  onPress,
  isActive,
  fontSize,
  fontWeight,
}) => (
  <ButtonContainer
    fontColor={fontColor}
    backgroundColor={backgroundColor}
    activeBackground={activeBackground}
    activeColor={activeColor}
    rounded={rounded}
    height={height}
    width={width}
    shadow={shadow}
    onClick={() => onPress()}
    active={isActive}
    fontSize={fontSize}
    fontWeight={fontWeight}
  >
    {children}
  </ButtonContainer>
)

const ButtonContainer = styled.button`
  cursor: pointer;
  border: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px")};
  color: ${({ fontColor, active, activeColor }) =>
    active ? activeColor : fontColor ? fontColor : "#fff"};
  background-color: ${({ backgroundColor, active, activeBackground }) =>
    active ? activeBackground : backgroundColor ? backgroundColor : "#000"};
  border-radius: ${({ rounded }) => (rounded ? "82px" : "10px")};
  height: ${({ height }) => (height ? height : "55px")};
  width: ${({ width }) => (width ? width : "100%")};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ shadow }) =>
    shadow ? "0px 4px 16px rgba(0, 0, 0, 0.1)" : null};
  outline: none;
  &:hover {
    background-color: ${({ activeBackground }) => activeBackground};
    color: ${({ activeColor }) => activeColor};
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    height: 51px;
  }
`

export default Button
