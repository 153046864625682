import React from "react"
import styled from "styled-components"

const H1 = ({ children, color }) => <Text color={color}>{children}</Text>

const Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 75px;
  color: ${({ color }) => (color ? color : "#000")};
  del {
    color: #00c0ee;
    font-size: 50px;
    text-decoration: none;
    @media only screen and (max-width: 768px) {
      font-size: 25px;
      line-height: 45px;
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 45px;
  }
`

export default H1
