import React from "react"
import styled from "styled-components"
import SmallP from "./fonts/smallp"

const CalendarCard = ({ children, onPress, isActive, title, text }) => (
  <CalendarCardContainer onClick={() => onPress()} active={isActive}>
    <SmallP color={isActive ? "#5C239B" : "#00C0EE"}>{title}</SmallP>

    <SmallP weight="400" color="#fff !important">
      {text}
    </SmallP>
  </CalendarCardContainer>
)

const CalendarCardContainer = styled.div`
  position: relative;
  z-index: 18;
  transition: all 0.3s;
  padding: 13px 40px 13px 10px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#00C0EE" : "#5C239B")};
  height: 90px;
  width: 167px;
  margin: 10px;
  @media only screen and (max-width: 768px) {
    width: 45%;
    padding: 13px;
    margin: 7px;
  }
  &:hover {
    background-color: #00c0ee;
    p {
      &:first-child {
        color: #5c239b;
      }
    }
  }
`
// const Desktop = styled.div`
//   display: block;
//   @media only screen and (max-width: 768px) {
//     display: none;
//   }
// `
// const Mobile = styled.div`
//   display: none;
//   @media only screen and (max-width: 768px) {
//     display: block;
//     p {
//       font-family: Poppins;
//       font-size: 14px;
//       color: white;
//       line-height: 21px;
//     }
//   }
// `
export default CalendarCard
