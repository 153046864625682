import React from "react"
import styled from "styled-components"

const P = ({ children, color, weight, align, lineHeight, mobileSize }) => (
  <Text
    align={align}
    color={color}
    weight={weight}
    lineHeight={lineHeight}
    mobileSize={mobileSize}
  >
    {children}
  </Text>
)

const Text = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : "bold")};
  font-size: 18px;
  color: ${({ color }) => (color ? color : "#000")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "21px")};
  text-align: ${({ align }) => (align ? align : "left")};
  span {
    color: #00c0ee;
  }
  @media only screen and (max-width: 768px) {
    font-size: ${({ mobileSize }) => (mobileSize ? mobileSize : "18px")};
  }
  del {
    color: #00c0ee;
    text-decoration: none;
  }
`

export default P
