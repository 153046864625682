import React from "react"
import styled from "styled-components"

export const Cta = styled.button`
  position: relative;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  background-color: #27ae60;
  border: none;
  border-radius: 82px;
  height: auto;
  padding: 0 25px;
  &:hover {
    cursor: pointer;
    background-color: #209b54;
  }
  min-width: 30%;
  height: 50px;
  width: auto;
  @media only screen and (max-width: 768px) {
    position: relative;
    height: 55px;
    margin-top: 10px;
    right: 0;
    width: 100%;
    font-size: 18px;
  }
`
