import React from "react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import { graphql, StaticQuery } from "gatsby"

import smilePicture from "../../images/smile.svg"
import groupPicture from "../../images/grouppicture.png"
import H2 from "../fonts/h2"

const JointSection = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiCommunaute {
              SousAnimation
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiCommunaute
            : null
          return (
            <JointContainer>
              <div className="section" />
              <Desktop>
                <Controller>
                  <Scene
                    indicators={false}
                    duration="60%"
                    triggerHook="onEnter"
                    reverse={true}
                    offset="250px"
                  >
                    <Timeline wrapper={<div className="parallax" />}>
                      <Tween
                        from={{
                          width: "1000%",
                          opacity: "1",
                          top: "20%",
                        }}
                        to={{
                          width: "101%",
                          opacity: "1",
                          top: "40%",
                        }}
                      >
                        <SmileImage
                          src={smilePicture}
                          alt="Element graphique non essentiel représentant des photos de personnes se transformant en sourire"
                        />
                      </Tween>
                    </Timeline>
                  </Scene>
                </Controller>
              </Desktop>
              <Mobile>
                <Controller>
                  <Scene
                    indicators={false}
                    duration="60%"
                    triggerHook="onEnter"
                    reverse={true}
                    offset="150px"
                  >
                    <Timeline wrapper={<div className="parallax" />}>
                      <Tween
                        from={{
                          width: "1000%",
                          opacity: "1",
                          top: "0%",
                        }}
                        to={{
                          width: "101%",
                          opacity: "1",
                          top: "20%",
                        }}
                      >
                        <SmileImage
                          src={smilePicture}
                          alt="Element graphique non essentiel représentant des photos de personnes se transformant en sourire"
                        />
                      </Tween>
                    </Timeline>
                  </Scene>
                </Controller>
              </Mobile>
              <div className="section" />
              <SmileImageContent
                src={groupPicture}
                alt="Element graphique non essentiel représentant des photos de personnes se transformant en sourire"
              />
              <TitleContainer>
                {/* <H2 align="center">
                  <ReactMarkdown
                    source={sectionDatas && sectionDatas.SousAnimation}
                  />
                </H2> */}
              </TitleContainer>
            </JointContainer>
          )
        }}
      />
    </>
  )
}

const Desktop = styled.div`
  display: block;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const Mobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const TitleContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 50%;
  margin: 60px auto 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const SmileImageContent = styled.img`
  margin: 0 auto;
  display: block;
  position: relative;
  top: 50px;
  z-index: 0;
`

const SmileImage = styled.img`
  position: absolute;
  width: 100%;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  z-index: 1;
  max-width: 1000%;
  @media only screen and (max-width: 768px) {
  }
`

const JointContainer = styled.div`
  padding: 0px 0 100px;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 768px) {
    padding: 0px 10% 75px;
  }
`

export default JointSection
