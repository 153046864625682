import React from "react"
import styled from "styled-components"

const SmallP = ({ children, color, weight, fontStyle, align, mobileSize }) => (
  <Text
    color={color}
    weight={weight}
    fontStyle={fontStyle}
    align={align}
    mobileSize={mobileSize}
  >
    {children}
  </Text>
)

const Text = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : "bold")};
  font-size: 14px;
  color: ${({ color }) => (color ? color : "#000")};
  line-height: 21px;
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : "none")};
  text-align: ${({ align }) => (align ? align : "none")};
  @media only screen and (max-width: 768px) {
    font-size: ${({ mobileSize }) => (mobileSize ? mobileSize : "14px")};
  }
  del {
    color: #00c0ee;
    text-decoration: none;
  }
  span {
    color: blue;
  }
`

export default SmallP
