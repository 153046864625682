import React from "react"
import { graphql, StaticQuery } from "gatsby"

import styled from "styled-components"

import P from "../fonts/p"

const CarrySection = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiIlsNousSoutiennent {
              Images {
                id
                image {
                  publicURL
                }
                alt_text_image
              }
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiIlsNousSoutiennent
            : null
          return (
            <CarryContainer>
              <TitleContainer>
                <P>
                  Ils nous <span> soutiennent </span>
                </P>
              </TitleContainer>
              <GreyBackground>
                <CarryContent>
                  {sectionDatas &&
                    sectionDatas.Images &&
                    sectionDatas.Images.map(({ image, alt_text_image, id }) => (
                      <LogoImage
                        src={image && image.publicURL}
                        alt={alt_text_image || ""}
                      />
                    ))}
                </CarryContent>
              </GreyBackground>
            </CarryContainer>
          )
        }}
      />
    </>
  )
}

const GreyBackground = styled.div`
  background: #f5f5f5;
`

const CarryContainer = styled.div`
  height: 100%;
  margin-bottom: 170px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`

const TitleContainer = styled.div`
  padding: 10px 8vw;
  max-width: 1440px;
  margin: 0 auto;
`
const LogoImage = styled.img`
  @media only screen and (max-width: 768px) {
    margin-bottom: 50px;
    width: 40%;
  }
`

const CarryContent = styled.div`
  padding: 10px 8vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
  max-width: 1440px;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }
`

export default CarrySection
