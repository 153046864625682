import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { graphql, StaticQuery } from "gatsby"

import programmeBackground from "../../images/programmebackground.png"
import H2 from "../fonts/h2"
import ImageListItem from "../imagelistitem"
import Button from "../button"

const ProgrammeSection = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiLeProgramme {
              id
              titre
              liste {
                icone {
                  childImageSharp {
                    fixed(width: 45, height: 45) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                titre
                texte
              }
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiLeProgramme
            : null
          return (
            <ProgrammeContainer>
              <TitleContainer>
                <H2>
                  <ReactMarkdown
                    source={sectionDatas ? sectionDatas.titre : ""}
                  />
                </H2>
              </TitleContainer>
              <ProgrammeContent>
                <LeftSection>
                  {sectionDatas &&
                    sectionDatas.liste
                      .slice(0, 1)
                      .map(({ _id, texte, titre, icone }) => (
                        <>
                          <ImageListItem
                            key={_id}
                            gatsbyFluidImage={
                              icone ? icone.childImageSharp.fixed : null
                            }
                            title={titre}
                            text={texte}
                          />
                          <ListItemSeparator />
                        </>
                      ))}
                </LeftSection>
                <RightSection>
                  {sectionDatas &&
                    sectionDatas.liste
                      .slice(1, 2)
                      .map(({ _id, texte, titre, icone }) => (
                        <>
                          <ImageListItem
                            key={_id}
                            gatsbyFluidImage={
                              icone ? icone.childImageSharp.fixed : null
                            }
                            title={titre}
                            text={texte}
                          />
                          <ListItemSeparator />
                        </>
                      ))}
                </RightSection>
              </ProgrammeContent>
              {/* <ButtonContainer>
                <a
                  href="https://live-for-good.org/files/Programme_Entrepreneur_for_Good_2020_2021.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button
                    backgroundColor="#00C0EE"
                    activeBackground="#E6833A"
                    fontColor="#fff"
                    rounded
                    fontSize="14px"
                    fontWeight="bold"
                  >
                    Consulter le programme complet
                  </Button>
                </a>
              </ButtonContainer> */}
            </ProgrammeContainer>
          )
        }}
      />
    </>
  )
}

const ProgrammeContainer = styled.div`
  height: 100%;
  padding: 50px 8vw 25px;
  background-image: url(${programmeBackground});
  background-size: cover;
  margin-bottom: 200px;
  @media only screen and (max-width: 768px) {
    padding: 150px 8vw 25px;
    margin-bottom: 50px;
  }
`

const TitleContainer = styled.div`
  position: relative;
  bottom: 80px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 8vw 0;
  @media only screen and (max-width: 768px) {
    bottom: 0;
    margin-bottom: 50px;
  }
`

const ProgrammeContent = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 8vw 0;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`

const RightSection = styled.div`
  width: 40%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
const LeftSection = styled.div`
  width: 40%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const ListItemSeparator = styled.div`
  height: 50px;
`

const ButtonContainer = styled.div`
  position: relative;
  bottom: -20px;
  margin: 0 auto;
  width: 90%;
  max-width: 303px;
  @media only screen and (max-width: 768px) {
    max-width: 90%;
    bottom: -100px;
  }
`

export default ProgrammeSection
