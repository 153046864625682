import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { useScrollYPosition } from "react-use-scroll-position"
import { graphql, StaticQuery } from "gatsby"

import quoteImg from "../../images/quote.png"

import H2 from "../fonts/h2"
import SmallP from "../fonts/smallp"

const CoachSection = () => {
  const [animmated, setAnimmated] = useState(false)

  let sectionRef = useRef()

  const scrollY = useScrollYPosition()

  useEffect(() => {
    if (scrollY > sectionRef.current.offsetTop) {
      setAnimmated(true)
    }
  }, [scrollY])

  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiCoach {
              id
              titre
              sousTitre
              coachVerbatime
              coachName
              coachStatus
              alumniVerbatime
              alumniName
              alumniStatus
              leftImage {
                childImageSharp {
                  fixed(width: 418, height: 418) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              rightImage {
                childImageSharp {
                  fixed(width: 418, height: 418) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas ? retrieveDatas.strapiCoach : null
          return (
            <CoachContainer ref={sectionRef}>
              <TitleContainer>
                <H2 mobileSize="25px">
                  <ReactMarkdown source={sectionDatas && sectionDatas.titre} />
                </H2>
                <TitleSeparator />
                <ParagraphContainer>
                  <SmallP weight="500">
                    {sectionDatas && sectionDatas.sousTitre}
                  </SmallP>
                </ParagraphContainer>
              </TitleContainer>
              <CoachContent>
                <LeftSection>
                  <Quote
                    src={quoteImg}
                    alt="Element graphique non essentiel représentant des guillements"
                  />
                  <TextSeparator />

                  <h5>
                    <ReactMarkdown
                      source={sectionDatas && sectionDatas.coachVerbatime}
                    />
                  </h5>
                  <TextSeparator />

                  <SmallP align="center">
                    {sectionDatas && sectionDatas.coachName}
                  </SmallP>
                  <SmallP weight="500" align="center">
                    {sectionDatas && sectionDatas.coachStatus}
                  </SmallP>
                  <SmallP align="center" color="#5C239B">
                    Mentor
                  </SmallP>
                </LeftSection>
                <MiddleSection>
                  <LeftMan
                    isAnnimated={animmated}
                    src={
                      sectionDatas &&
                      sectionDatas.leftImage.childImageSharp.fixed.src
                    }
                    alt="Photo profil de coach"
                  />
                  <RightMan
                    isAnnimated={animmated}
                    src={
                      sectionDatas &&
                      sectionDatas.rightImage.childImageSharp.fixed.src
                    }
                    alt="Photo profil d'entrepreneur"
                  />
                </MiddleSection>
                <RightSection>
                  <Quote
                    src={quoteImg}
                    alt="Element graphique non essentiel représentant des guillements"
                  />
                  <TextSeparator />
                  <h5>
                    <ReactMarkdown
                      source={sectionDatas && sectionDatas.alumniVerbatime}
                    />
                  </h5>
                  <TextSeparator />
                  <SmallP align="center">
                    {sectionDatas && sectionDatas.alumniName}
                  </SmallP>
                  <SmallP weight="500" align="center">
                    {sectionDatas && sectionDatas.alumniStatus}
                  </SmallP>
                  <SmallP align="center" color="#00C0EE">
                    Jeune engagé·e
                  </SmallP>
                </RightSection>
              </CoachContent>
            </CoachContainer>
          )
        }}
      />
    </>
  )
}

const TextSeparator = styled.div`
  height: 20px;
`

const CoachContainer = styled.div`
  height: 100%;
  padding: 150px 8vw 143px;
  @media only screen and (max-width: 768px) {
    padding: 80px 8vw 110px;
  }
`

const TitleContainer = styled.div`
  position: relative;
  bottom: 30px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 80px;
  }
`

const ParagraphContainer = styled.div`
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const CoachContent = styled.div`
  position: relative;
  display: flex;
`

const Quote = styled.img`
  display: block;
  margin: 0 auto;
`

const RightSection = styled.div`
  width: 33%;
  padding: 100px 5% 0 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    padding: 0;
    width: 50%;
    margin-top: 250px;
  }
  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #000;
    line-height: 21px;

    text-align: center;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  del {
    color: #00c0ee;
    text-decoration: none;
  }
`
const LeftSection = styled.div`
  width: 33%;
  padding: 100px 5% 0 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    padding: 0;
    width: 50%;
    margin-top: 250px;
  }
  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #000;
    line-height: 21px;

    text-align: center;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  del {
    color: #00c0ee;
    text-decoration: none;
  }
`
const MiddleSection = styled.div`
  padding: 100px 5% 0 5%;
  width: 33%;
  position: relative;
  @media only screen and (max-width: 768px) {
    padding: 0;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`
const LeftMan = styled.img`
  position: absolute;
  left: ${({ isAnnimated }) => (isAnnimated ? 0 : "-10%")};
  z-index: 1;
  transition: all 1s;
  max-width: 214px;
  max-height: 214px;
  @media only screen and (max-width: 768px) {
    left: 0 !important;
    max-width: 180px;
    max-height: 180px;
  }
`
const RightMan = styled.img`
  position: absolute;
  right: ${({ isAnnimated }) => (isAnnimated ? 0 : "-10%")};
  transition: all 1s;
  max-width: 214px;
  max-height: 214px;
  @media only screen and (max-width: 768px) {
    right: 0 !important;
    max-width: 180px;
    max-height: 180px;
  }
`

const TitleSeparator = styled.div`
  height: 10px;
`

export default CoachSection
