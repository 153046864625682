//  /!\ TODO SPLIT DATA BY YEARS

import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import styled from "styled-components"

import H2 from "../fonts/h2"
import P from "../fonts/p"
import CalendarCard from "../calendarcard"
import SmallP from "../fonts/smallp"

const CalendarSection = () => {
  const [currentDate, setCurrentDate] = useState(null)

  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiCalendrier {
              id
              titre
              elementscalendrier {
                id
                titre
                annee
                texte
                sousTitre
                image {
                  childImageSharp {
                    fluid(maxWidth: 686, maxHeight: 440) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiCalendrier
            : null
          if (!currentDate) {
            setCurrentDate(sectionDatas.elementscalendrier[0])
          }
          return (
            <CalendarContainer>
              <TitleContainer>
                <H2>
                  <ReactMarkdown
                    source={sectionDatas ? sectionDatas.titre : ""}
                  />
                </H2>
              </TitleContainer>
              <SectionContainer>
                <CalendarContent>
                  <>
                    {/* <TitleDateContainer>
                      <P color="#00C0EE">2020</P>
                    </TitleDateContainer> */}

                    <CalendarBlock>
                      {sectionDatas &&
                        sectionDatas.elementscalendrier.map(
                          ({ id, titre, sousTitre, annee }) =>
                            annee === "2020" && (
                              <CalendarCard
                                title={titre}
                                text={sousTitre}
                                isActive={currentDate && currentDate.id === id}
                                onPress={() => {
                                  setCurrentDate(
                                    sectionDatas.elementscalendrier.find(
                                      x => x.id === id
                                    )
                                  )
                                }}
                              />
                            )
                        )}
                    </CalendarBlock>
                  </>
                  <>
                    <TitleDateContainer>
                      <P color="#00C0EE">2021</P>
                    </TitleDateContainer>

                    <CalendarBlock>
                      {sectionDatas &&
                        sectionDatas.elementscalendrier.map(
                          ({ id, titre, sousTitre, annee }) =>
                            annee === "2021" && (
                              <CalendarCard
                                title={titre}
                                text={sousTitre}
                                isActive={currentDate && currentDate.id === id}
                                onPress={() =>
                                  setCurrentDate(
                                    sectionDatas.elementscalendrier.find(
                                      x => x.id === id
                                    )
                                  )
                                }
                              />
                            )
                        )}
                    </CalendarBlock>
                  </>
                </CalendarContent>
                <DetailContent>
                  {currentDate && (
                    <DetailCard>
                      <DetailImage>
                        <Img fluid={currentDate.image.childImageSharp.fluid} />
                      </DetailImage>
                      <TextSection>
                        <P color="#00C0EE">{currentDate.titre}</P>
                        <TextSeparator />
                        <SmallP>{currentDate.sousTitre}</SmallP>
                        <TextSeparator />
                        <SmallP weight="500">{currentDate.texte}</SmallP>
                      </TextSection>
                    </DetailCard>
                  )}
                </DetailContent>
                <DetailContentMobile>
                  <OrangeBar />
                  {currentDate && (
                    <DetailCard>
                      <DetailImage>
                        <Img
                          fluid={currentDate.image.childImageSharp.fluid}
                          style={{ height: "100%" }}
                        />
                      </DetailImage>
                      <TextSection>
                        <P color="#00C0EE">{currentDate.titre}</P>
                        <TextSeparator />
                        <SmallP>{currentDate.sousTitre}</SmallP>
                        <TextSeparator />
                        <SmallP weight="500">{currentDate.texte}</SmallP>
                      </TextSection>
                    </DetailCard>
                  )}
                </DetailContentMobile>
              </SectionContainer>
            </CalendarContainer>
          )
        }}
      />
    </>
  )
}

const CalendarContainer = styled.div`
  height: 100%;
  padding: 0 8vw 105px;
  @media only screen and (max-width: 768px) {
    padding: 0;
    margin-bottom: 110px;
  }
`
const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const TitleContainer = styled.div`
  position: relative;
  bottom: 30px;
  @media only screen and (max-width: 768px) {
    padding: 50px 8vw 0;
  }
`

const CalendarContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px 0 25px 5%;
  max-width: 691px;
  width: 60%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px 0 0 0;
  }
`

const CalendarBlock = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin-top: 10px;

  @media only screen and (max-width: 768px) {
    justify-content: space-between;
    padding: 0px calc(8vw - 9px);
  }
`

const TitleDateContainer = styled.div`
  padding-left: 10px;
  @media only screen and (max-width: 768px) {
    padding: 0 8vw;
  }
`
const DetailContent = styled.div`
  padding-top: 91px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const DetailContentMobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const DetailCard = styled.div`
  max-width: 343px;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    display: flex;
  }
`

const DetailImage = styled.div`
  height: 217px;
  @media only screen and (max-width: 768px) {
    width: 50%;
    height: auto;
  }
`

const OrangeBar = styled.div`
  height: 7px;
  background: #00c0ee;
`
const TextSection = styled.div`
  background: #f5f5f5;
  padding: 25px;
  @media only screen and (max-width: 768px) {
    width: 70%;
    padding: 20px;
  }
`

const TextSeparator = styled.div`
  height: 10px;
`

export default CalendarSection
