import React, { useState } from "react"
import styled from "styled-components"

import ReCAPTCHA from "react-google-recaptcha"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/sections/hero"
import JointSection from "../components/sections/joint"
import CommunitySection from "../components/sections/community"
import CoachSection from "../components/sections/coach"
import NumberBannerSection from "../components/sections/numberbanner"
import AlumnisSection from "../components/sections/alumnis"
import CampusSection from "../components/sections/campus"
import AlumniProjectSection from "../components/sections/alumniprojets"
import PlateformeSection from "../components/sections/plateforme"
import ProgrammeSection from "../components/sections/programme"
import CarrySection from "../components/sections/carry"
import CalendarSection from "../components/sections/calendar"
import LastCallSection from "../components/sections/lastcall"
import WhiteBookSection from "../components/sections/whitebook"
import FixedHeaderSection from "../components/sections/fixedheader"

const recaptchaPublicKey = process.env.GATSBY_RECAPTCHA_PUBLIC_KEY

const IndexPage = () => {
  const [recaptcha, setRecaptcha] = useState(false)
  const recaptchaRef = React.createRef()

  return (
    <>
      <SEO title=" " />
      <HeroSection recaptchaRef={recaptchaRef} recaptcha={recaptcha} />
      <Layout>
        <FixedHeaderSection recaptchaRef={recaptchaRef} recaptcha={recaptcha} />
        {/* <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={recaptchaPublicKey}
          badge="bottomright"
          onChange={() => setRecaptcha(true)}
        /> */}
        {/* <JointSection /> */}
      </Layout>
      <CommunitySection />
      {/* <Layout>
        <CoachSection />
      </Layout> */}
      <NumberBannerSection />
      <SpacerBanner />
      <AlumnisSection />
      <Layout>
        {/* <CampusSection /> */}
        <AlumniProjectSection />
        {/* <PlateformeSection /> */}
      </Layout>
      <Spacer />
      <ProgrammeSection />
      {/* <Layout>
        <CalendarSection />
      </Layout> */}
      {/* <CarrySection /> */}
      <LastCallSection recaptchaRef={recaptchaRef} recaptcha={recaptcha} />
      {/* <WhiteBookSection recaptchaRef={recaptchaRef} recaptcha={recaptcha} /> */}
    </>
  )
}

export default IndexPage

const Spacer = styled.div`
  height: 200px;
  @media only screen and (max-width: 768px) {
    height: 20px;
  }
`
const SpacerBanner = styled.div`
  height: 20px;
  @media only screen and (max-width: 768px) {
    height: 20px;
  }
`
