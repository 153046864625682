import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { graphql, StaticQuery } from "gatsby"

import communityBackground from "../../images/communitybackground.png"

import P from "../fonts/p"
import SmallP from "../fonts/smallp"
import H2 from "../fonts/h2"
import ImageListItem from "../imagelistitem"

const CommunitySection = ({ retrieveDatas }) => {
  return (
    <>
      <>
        <StaticQuery
          query={graphql`
            query {
              strapiCommunaute {
                SousAnimation
                titre
                Image {
                  publicURL
                }
                liste {
                  icone {
                    publicURL
                  }
                  alt_text_image
                  titre
                  texte
                }
              }
            }
          `}
          render={retrieveDatas => {
            const sectionDatas = retrieveDatas
              ? retrieveDatas.strapiCommunaute
              : null
            return (
              <CommunityContainer>
                <Content>
                  <TitleContainer>
                    <H2>
                      <ReactMarkdown
                        source={sectionDatas && sectionDatas.SousAnimation}
                      />
                    </H2>
                    <SmallP weight="500">
                      <ReactMarkdown
                        source={sectionDatas && sectionDatas.titre}
                      />
                    </SmallP>
                  </TitleContainer>
                  <CommunityContent>
                    <LeftSection>
                      {sectionDatas &&
                        sectionDatas.liste.map(
                          ({ _id, titre, texte, icone, alt_text_image }) => (
                            <>
                              <ImageListItem
                                id={_id}
                                image={icone?.publicURL}
                                title={titre}
                                text={texte}
                                // limitText={2}
                                alt={alt_text_image || ""}
                              />
                              <ListItemSeparator />
                            </>
                          )
                        )}
                    </LeftSection>
                    <RightSection>
                      {sectionDatas && sectionDatas.Image && (
                        <InfosImage
                          src={sectionDatas.Image.publicURL}
                          alt="Image représentant notre communauté : entreprises, experts, entrepreneurs, coachs et vous"
                        />
                      )}
                    </RightSection>
                  </CommunityContent>
                </Content>
              </CommunityContainer>
            )
          }}
        />
      </>
    </>
  )
}

const CommunityContainer = styled.div`
  height: 100%;
`

const Content = styled.div`
  height: 100%;
  margin: 0 auto;
  padding: 50px 8vw 25px;
  max-width: 1440px;
`

const TitleContainer = styled.div`
  position: relative;
  bottom: 30px;
  @media only screen and (max-width: 768px) {
    bottom: 0px;
  }
`

const CommunityContent = styled.div`
  position: relative;
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const RightSection = styled.div`
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
const LeftSection = styled.div`
  width: 50%;
  padding: 100px 21% 0 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 60px;
    padding: 25px 10% 0 0;
  }
`

const InfosImage = styled.img`
  width: 100%;
  height: auto;
`

const ListItemSeparator = styled.div`
  height: 30px;
`

export default CommunitySection
