import React, { useEffect } from "react"
import styled, { keyframes } from "styled-components"
import ReactMarkdown from "react-markdown"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import heroBackground from "../../images/headerGI2.jpg"
import logo from "../../images/logo_white_pink.png"
import orangeArrow from "../../images/orangearrow.png"
import heroParticle1 from "../../images/heroparticle1.png"
import heroParticle2 from "../../images/heroparticle2.png"
import heroParticle3 from "../../images/heroparticle3.png"
import heroParticle4 from "../../images/heroparticle4.png"

import H1 from "..//fonts/h1"
import H4 from "../fonts/h4"
import SmallForm from "../smallform"
import P from "../fonts/p"
import { Cta } from "../cta"

import H5 from "../fonts/h5"

const HeroSection = ({ recaptchaRef, recaptcha }) => {
  let viewportHeight = 0

  useEffect(() => {
    viewportHeight = document
      ? Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      : 0
  }, [])
  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiAccueil {
              id
              baseline
              soustitre
              cta
              Background {
                childImageSharp {
                  fluid(maxWidth: 2880) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              valeurs {
                valeur
              }
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiAccueil
            : null
          return (
            <HeroContainer>
              <BackgroundImage
                fluid={
                  sectionDatas && sectionDatas.Background.childImageSharp.fluid
                }
                alt="Image de fond"
              >
                <Particle1 src={heroParticle1} alt="heroParticle1" />
                <Particle2 src={heroParticle2} alt="heroParticle2" />
                <Particle3 src={heroParticle3} alt="heroParticle3" />
                <Particle4 src={heroParticle4} alt="heroParticle4" />
                <HeroContent>
                  <LogoRow>
                    <a
                      href="https://live-for-good.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Logo src={logo} alt="logo" />
                    </a>
                  </LogoRow>
                  <Center>
                    <TitleRow>
                      <H1 color="#FFF">
                        <CustomH1>
                          <ReactMarkdown
                            source={sectionDatas && sectionDatas.baseline}
                          />
                        </CustomH1>
                      </H1>
                      <Spacer />
                      <H5 color="#FFF" weight="600" lineHeight="27px">
                        <CustomH5>
                          <ReactMarkdown
                            source={sectionDatas && sectionDatas.soustitre}
                          />
                        </CustomH5>
                      </H5>
                    </TitleRow>
                    <FormRow>
                      <P
                        text={sectionDatas && sectionDatas.soustitre}
                        color="#FFF"
                        weight="700"
                      />
                      <FormContainer>
                        {/* <SmallForm
                        recaptchaRef={recaptchaRef}
                        recaptcha={recaptcha}
                        submitText={sectionDatas && sectionDatas.cta}
                      /> */}
                        <a
                          href="https://liveforgood282120.typeform.com/to/TbAh8waY"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Cta>{sectionDatas && sectionDatas.cta}</Cta>
                        </a>
                      </FormContainer>
                    </FormRow>
                  </Center>
                  {/* <ArrowRow>
                  <Arrow
                    onClick={() =>
                      window.scroll({
                        top: viewportHeight,
                        behavior: "smooth",
                      })
                    }
                    src={orangeArrow}
                    alt="Arrow"
                  />
                </ArrowRow> */}
                  {/* <NavRow>
                  {sectionDatas &&
                    sectionDatas.valeurs.map(({ valeur, id }, index) => (
                      <H4
                        key={id}
                        text={valeur}
                        color="#fff"
                        fontStyle="italic"
                      />
                    ))}
                </NavRow> */}
                </HeroContent>
              </BackgroundImage>
            </HeroContainer>
          )
        }}
      />
    </>
  )
}

const ParticleAnnimation1 = keyframes`
 0% { transform: translatey(20px) }
 50% { transform: translatey(-20px)  }
 100% { transform: translatey(20px)  }
`
const ParticleAnnimation2 = keyframes`
 0% { transform: translatey(-20px) }
 50% { transform: translatey(20px)  }
 100% { transform: translatey(-20px)  }
`
const CustomH1 = styled.div`
  @media only screen and (max-width: 768px) {
    p {
      display: inline-block;
      font-size: 21px;
      del {
        font-size: 21px;
      }
    }
  }
  @media only screen and (max-height: 688px) and (min-width: 768px) {
    font-size: 39px;
    line-height: 50px;
  }
`
const CustomH5 = styled.div`
  @media only screen and (max-height: 688px) and (min-width: 768px) {
    font-size: 17px;
  }
  max-width: 950px;
`
const HeroContainer = styled.div`
  position: relative;
`
const Particle1 = styled.img`
  position: absolute;
  top: 25px;
  left: 20%;
  animation-name: ${ParticleAnnimation1};
  animation-duration: 6s;
  animation-iteration-count: infinite;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const Particle2 = styled.img`
  position: absolute;
  top: 140px;
  right: 250px;
  animation-name: ${ParticleAnnimation2};
  animation-duration: 6s;
  animation-iteration-count: infinite;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const Particle3 = styled.img`
  position: absolute;
  top: 260px;
  right: 400px;
  animation-name: ${ParticleAnnimation1};
  animation-duration: 6s;
  animation-iteration-count: infinite;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const Particle4 = styled.img`
  position: absolute;
  bottom: 10vh;
  left: 5vw;
  animation-name: ${ParticleAnnimation2};
  animation-duration: 6s;
  animation-iteration-count: infinite;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const HeroContent = styled.div`
  height: 100vh;
  padding: 50px 8vw 25px;
  @media only screen and (max-width: 768px) {
    padding: 0 7% 25px;
    height: 90vh;
  }
`

const Logo = styled.img`
  position: relative;
  z-index: 5;
  width: 120px;
  height: 77px;
  @media only screen and (max-width: 768px) {
    width: 68px;
    height: 43px;
  }
`
const LogoRow = styled.div`
  margin-bottom: 48px;
  @media only screen and (max-width: 768px) {
    top: 4vh;
    position: absolute;
    margin-bottom: 1vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
const TitleRow = styled.div`
  position: relative;
  z-index: 1;
`
const FormRow = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
`
const FormContainer = styled.div`
  position: relative;
  max-width: 85%;
  width: 90%;
  display: flex;
  justify-content: left;
`
const ArrowRow = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15vh;
  left: 0;
  width: 100%;
  @media only screen and (max-width: 768px) {
    bottom: 13vh;
  }
`
const Arrow = styled.img`
  cursor: pointer;
`
const NavRow = styled.div`
  position: absolute;
  bottom: 4vh;
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0%;
  padding: 0 10%;
  @media only screen and (max-width: 768px) {
    padding: 0 3%;
    bottom: 7vh;
  }
  @media only screen and (max-width: 400px) {
    display: none;
  }
`

const Spacer = styled.div`
  height: 0;
  @media only screen and (max-width: 768px) {
    height: 2vh;
  }
`

const Center = styled.div`
  position: absolute;
  top: 55%;
  transform: translateY(-45%);
  @media only screen and (max-width: 768px) {
    top: 55vh;
  }
`

export default HeroSection
