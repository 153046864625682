import React from "react"
import styled from "styled-components"

const H4 = ({ text, color, fontStyle }) => (
  <Text color={color} fontstyle={fontStyle}>
    {text}
  </Text>
)

const Text = styled.h4`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;  
    font-style: ${({ fontstyle }) => (fontstyle ? "italic" : "none")};
    color: ${({ color }) => (color ? color : "#000")};
    @media only screen and (max-width: 768px) {
        font-size: 10px;
  }
}
`

export default H4
