import React from "react"
import styled, { keyframes } from "styled-components"
import ReactMarkdown from "react-markdown"
import { graphql, StaticQuery } from "gatsby"

import P from "../fonts/p"
import SmallP from "../fonts/smallp"

import H2 from "../fonts/h2"
import { Link } from "gatsby"
import Slider from "react-slick"

import Layout from "../layout"

const AlumnisSection = () => {
  const settings = {
    className: "slider variable-width",
    dots: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    appendDots: dots => <Dots>{dots}</Dots>,
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiLesAlumnis {
              id
              titre
              Description
              alumnis {
                Prenom
                poste
                texte
                lien
                image {
                  childImageSharp {
                    fixed(width: 425, height: 639) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiLesAlumnis
            : null
          return (
            <AlumnisContainer>
              <Layout>
                <TitleContainer>
                  <H2 mobileSize="25px" align="left">
                    <ReactMarkdown
                      source={sectionDatas ? sectionDatas.titre : ""}
                    />
                  </H2>
                  <SmallP align="left" weight="500">
                    <ReactMarkdown
                      source={sectionDatas ? sectionDatas.Description : ""}
                    />
                  </SmallP>
                </TitleContainer>
              </Layout>
              <AlumnisContentMobile>
                <MobileSlider>
                  <Slider {...settings}>
                    {sectionDatas &&
                      sectionDatas.alumnis.map(
                        ({ _id, image, Prenom, poste, texte, lien }) => {
                          return (
                            <ProfilCard
                              background={image.childImageSharp.fixed.src}
                              id={_id}
                            >
                              <div class="purple">
                                <PurpleShadow />
                              </div>
                              <InfoContainer>
                                <Title>{Prenom}</Title>
                                <ReactMarkdown source={poste} />
                                <LineSeparator />
                                {texte}
                                <Link to={lien}>{lien}</Link>
                              </InfoContainer>
                            </ProfilCard>
                          )
                        }
                      )}
                  </Slider>
                </MobileSlider>
              </AlumnisContentMobile>
              <AlumnisContent>
                {sectionDatas &&
                  sectionDatas.alumnis.map(
                    ({ _id, image, Prenom, poste, texte, lien }) => {
                      const cardWidth = 100 / sectionDatas.alumnis.length
                      return (
                        <ProfilCard
                          background={image.childImageSharp.fixed.src}
                          id={_id}
                          initialWidth={cardWidth}
                        >
                          <div class="purple">
                            <PurpleShadow />
                          </div>
                          <InfoContainer>
                            <Title>{Prenom}</Title>
                            <ReactMarkdown source={poste} />
                            <LineSeparator />
                            {texte}
                            <Link to={lien}>{lien}</Link>
                          </InfoContainer>
                        </ProfilCard>
                      )
                    }
                  )}
              </AlumnisContent>
            </AlumnisContainer>
          )
        }}
      />
    </>
  )
}

const showText = keyframes`
  0% { opacity: 0;}
  50% { opacity: 0;}
  100% {opacity: 1;}

`
const MobileSlider = styled.div`
  width: 100%;
  .slick-slide {
    width: 70vw;
    @media only screen and (max-width: 325px) {
      width: 65vw;
    }
  }
  .slick-active {
    .purple {
      display: none;
    }
  }
  .slick-list {
    @media only screen and (max-width: 768px) {
      /* height: auto; */
    }
  }
`

const AlumnisContainer = styled.div`
  height: 100%;
  padding: 80px 0;
  @media only screen and (max-width: 768px) {
    padding: 50px 0 25px;
  }
`

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 45px;
  padding: 0 8vw;
  @media only screen and (max-width: 768px) {
    h2 {
      text-align: left;
    }
  }
`

const AlumnisContent = styled.div`
  min-height: 80vh;
  position: relative;
  display: flex;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const AlumnisContentMobile = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    width: 100%;
    position: relative;
    display: flex;
  }
`
const PurpleShadow = styled.section`
  background: rgba(116, 77, 158, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 5;
`

const ProfilCard = styled.div`
  background-image: ${({ background }) => `url(${background})`};
  transition: all 1s;
  background-position: center;
  background-size: cover;
  min-height: 70vh;
  width: ${({ initialWidth }) => initialWidth}%;
  position: relative;
  filter: blur(1px);
  transform: scale(1.01);
  @media only screen and (max-width: 768px) {
    width: 100%;

    filter: blur(0);
  }
  a {
    margin-top: 22px;
    margin-bottom: 22px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #1ec3eb;
  }
  p,
  h3,
  a,
  div {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    &.purple {
      display: block;
    }
  }
  &:hover {
    transition: all 1s;
    filter: blur(0);
    background-position: top;
    width: 35%;
    p,
    h3,
    a,
    div {
      animation: ${showText};
      animation-duration: 1s;

      display: block;
    }
    section {
      background: transparent;
    }
  }
  @media only screen and (max-width: 768px) {
    &:hover {
      filter: blur(0);
      width: 80vw;
      p,
      h3,
      a,
      div {
        display: block;
      }
      section {
        background: rgba(116, 77, 158, 0.7);
      }
    }
  }
`
const InfoContainer = styled.div`
  font-family: Poppins;
  position: absolute;
  bottom: 0%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 36%,
    rgba(251, 250, 250, 1) 100%
  );
  width: 100%;
  padding: 0 15%;
  del {
    text-decoration: none;
    color: #00c0ee;
  }
  @media only screen and (max-width: 768px) {
    min-height: 40vh;
  }
`
const LineSeparator = styled.div`
  height: 20px;
`
const Title = styled.h3`
  font-family: Poppins;
  font-weight: bold;
  font-size: 25px;
  line-height: 37px;
  color: #5c239b;
`
const Dots = styled.div`
  right: 32px !important;
  bottom: 23px !important;
  width: auto !important;
  li {
    background: #f5f5f5;
    width: 11px !important;
    height: 11px !important;
    border-radius: 50px;
    button:before {
      display: none;
    }
    &.slick-active {
      background: #00c0ee;
    }
  }
  @media only screen and (max-width: 768px) {
    right: 50% !important;
    bottom: 10px !important;
    transform: translateX(50%);
    li {
      &.slick-active {
        background: #5c239b;
      }
    }
  }
`

export default AlumnisSection
