import React from "react"
import styled from "styled-components"

const Li = ({ children, fontSize, color, weight, fontStyle, align }) => (
  <Text
    fontSize={fontSize}
    color={color}
    weight={weight}
    fontStyle={fontStyle}
    align={align}
  >
    <Dot />
    {children}
  </Text>
)

const Text = styled.p`
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: ${({ weight }) => (weight ? weight : "bold")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  color: ${({ color }) => (color ? color : "#000")};
  line-height: 21px;
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : "none")};
  text-align: ${({ align }) => (align ? align : "none")};
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    justify-content: end;
    margin-bottom: 10px;
    font-size: 12px;
  }
  span {
    color: #00c0ee;
  }
`
const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: #00c0ee;

  border-radius: 50px;
  margin-right: 5px;
  @media only screen and (max-width: 768px) {
    margin-right: 15px;
  }
`
export default Li
