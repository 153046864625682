import React from "react"
import styled from "styled-components"
import P from "./fonts/p"
import ClampLines from "react-clamp-lines"
import nextId from "react-id-generator"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

const ImageListItem = ({
  image,
  gatsbyFluidImage,
  title,
  text,
  limitText,
  alt,
}) => (
  <Container>
    <LogoContainer>
      {gatsbyFluidImage ? (
        <Img fixed={gatsbyFluidImage} />
      ) : (
        <Logo src={image} alt={alt} />
      )}
    </LogoContainer>
    <TextContainer>
      <P>
        <ReactMarkdown source={title} />
      </P>
      <Separator />

      {limitText ? (
        <ClampLines
          text={text}
          id={nextId()}
          lines={limitText}
          ellipsis="..."
          innerElement="h5"
          buttons={false}
        />
      ) : (
        <h5>
          <ReactMarkdown source={text} />
        </h5>
      )}
    </TextContainer>
  </Container>
)

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

const LogoContainer = styled.div`
  width: 10%;
  margin-right: 25px;
`
const Logo = styled.img`
  width: 100%;
`
const Separator = styled.div`
  height: 5px;
`
const TextContainer = styled.div`
  width: 90%;
  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    span {
      color: #00c0ee;
    }
  }
`

export default ImageListItem
