import React from "react"
import styled from "styled-components"

const H2 = ({ children, text, color, align, lineHeight, mobileSize }) => (
  <Text
    color={color}
    align={align}
    lineHeight={lineHeight}
    mobileSize={mobileSize}
  >
    {children}
  </Text>
)

const Text = styled.h2`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: ${({ color }) => (color ? color : "#000")};
  text-align: ${({ align }) => (align ? align : "none")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "45px")};
  del {
    color: #00c0ee;
    text-decoration: none;
  }
  span {
    color: blue;
  }
  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
`

export default H2
