import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"

import linkedinLogo from "../../images/linkedinlogo.png"
import instagramLogo from "../../images/instagramlogo.png"
import facebookLogo from "../../images/facebooklogo.png"
import liveforgoodLogo from "../../images/favicon_reverse.png"

// import Elem1 from "../../images/retrieveelem1.png"
// import Elem2 from "../../images/retrieveelem2.png"
import Elem3 from "../../images/retrieveelem3.png"
import Elem4 from "../../images/retrieveelem4.png"
// import Elem1mobile from "../../images/retrieveelemmobile1.png"
// import Elem2mobile from "../../images/retrieveelemmobile2.png"
// import Elem3mobile from "../../images/retrieveelemmobile3.png"
// import Elem4mobile from "../../images/retrieveelemmobile4.png"

import P from "../fonts/p"
import SmallP from "../fonts/smallp"

import SmallForm from "../smallform"

const WhiteBookSection = ({ retrieveDatas, recaptchaRef, recaptcha }) => {
  const [curentUrl, setCurentUrl] = useState(false)

  useEffect(() => {
    setCurentUrl(window.location.href)
  }, [])
  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiLivreBlanc {
              titre
              sousTitre
              bouton
              facebook
              instagram
              linkedIn
              liveforgood
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiLivreBlanc
            : null
          return (
            <WhiteBookContainer>
              <WhiteBook>
                <FloatElem3 src={Elem3} alt="Element graphique non essentiel" />
                <FloatElem4 src={Elem4} alt="Element graphique non essentiel" />

                <WhiteBookContent>
                  <Title>{sectionDatas && sectionDatas.titre}</Title>
                  <P color="#333333" align="center">
                    {sectionDatas && sectionDatas.sousTitre}
                  </P>
                  <FormContainer>
                    <SmallForm
                      recaptchaRef={recaptchaRef}
                      recaptcha={recaptcha}
                      submitText={sectionDatas && sectionDatas.bouton}
                      tags={[
                        "Entrepreneur for Good > Prospects 2021",
                        "Entrepreneur for Good > Prospects 2021 > Landing page",
                        "Entrepreneur for Good > Prospects 2021 > Landing page > Rencontre entrepreneur CTA",
                      ]}
                      ctaFrom="calendar"
                      mandrillTemplate="a-p-inscription-continue-aux-rencontres-2021"
                      subject="Inscription aux rencontres entrepreneurs"
                    />
                  </FormContainer>
                  <SmallP color="#333333" align="center">
                    Des questions ?
                  </SmallP>
                  <a href="mailto:candidatures@live-for-good.org">
                    <LinkUnderlined>
                      <SmallP color="#333333" align="center">
                        Contactez-nous
                      </SmallP>
                    </LinkUnderlined>
                  </a>
                  <br />
                  <SocialRow>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={sectionDatas ? sectionDatas.facebook : "/"}
                    >
                      <SocialImg
                        src={facebookLogo}
                        alt="Visiter notre page Facebook (nouvel onglet)"
                      />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={sectionDatas ? sectionDatas.instagram : "/"}
                    >
                      <SocialImg
                        src={instagramLogo}
                        alt="Visiter notre page Instagram (nouvel onglet)"
                      />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={sectionDatas ? sectionDatas.linkedIn : "/"}
                    >
                      <SocialImg
                        src={linkedinLogo}
                        alt="Visiter notre page LinkedIn (nouvel onglet)"
                      />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={sectionDatas ? sectionDatas.liveforgood : "/"}
                    >
                      <SocialImg
                        src={liveforgoodLogo}
                        alt="Visiter site officiel (nouvel onglet)"
                      />
                    </a>
                  </SocialRow>
                </WhiteBookContent>
              </WhiteBook>
              <ShareSection>
                <SmallP color="#000" align="center">
                  Partagez la page
                </SmallP>
                <NetworkSection>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${curentUrl}`}
                  >
                    <SocialImg
                      src={facebookLogo}
                      alt="Visiter notre page Facebook (nouvel onglet)"
                    />
                  </a>
                  <SpaceShare />
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${curentUrl}&title=&summary=&source=`}
                  >
                    <SocialImg
                      src={linkedinLogo}
                      alt="Visiter notre page LinkedIn (nouvel onglet)"
                    />
                  </a>
                </NetworkSection>
              </ShareSection>
            </WhiteBookContainer>
          )
        }}
      />
    </>
  )
}

const LinkUnderlined = styled.div`
  text-decoration: underline;
  text-decoration-color: black;
`
const WhiteBook = styled.div`
  @media only screen and (max-width: 768px) {
    position: relative;
    height: 100%;
  }
`
const NetworkSection = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`
const SpaceShare = styled.div`
  width: 40px;
`
const ShareSection = styled.div`
  display: none;
  background: white;
  padding: 100px 0;
  position: relative;
  z-index: 9;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`
const FloatElem3 = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const FloatElem4 = styled.img`
  position: absolute;
  right: 0;
  bottom: 10%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const WhiteBookContainer = styled.div`
  position: relative;
  background: #f5f5f5;

  justify-content: center;
  padding: 0 0 20px;
  margin-bottom: 70px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 70px;
    padding: 0;
  }
`
const WhiteBookContent = styled.div`
  z-index: 2;
  position: relative;
  max-width: 495px;
  width: 80%;
  margin: 0 auto;
  padding-top: 50px;

  @media only screen and (max-width: 768px) {
    margin: 50px auto;
  }
`
const FormContainer = styled.div`
  padding: 40px 0% 40px;
  display: flex;
  justify-content: center;
`

const Title = styled.h3`
  font-family: Poppins;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #333333;
  margin-bottom: 25px;
  @media only screen and (max-width: 768px) {
    font-size: 25px;
  }
`

const SocialRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 32%;
  @media only screen and (max-width: 768px) {
    padding: 0 25%;
  }
`
const SocialImg = styled.img`
  width: 36px;
  height: 36px;
`
export default WhiteBookSection
