import React from "react"
import styled from "styled-components"

const H3 = ({ children, color, align, mobileSize }) => (
  <Text color={color} align={align} mobileSize={mobileSize}>
    {children}
  </Text>
)

const Text = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  text-align: ${({ align }) => (align ? align : "left")};
  color: ${({ color }) => (color ? color : "#000")};

  @media only screen and (max-width: 768px) {
    font-size: ${({ mobileSize }) => (mobileSize ? mobileSize : "30px")};
  }
`

export default H3
