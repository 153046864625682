import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import P from "./fonts/p"
import SmallP from "./fonts/smallp"

const ProjectCard = ({
  profilImage,
  image,
  fluidGatsbyImage,
  title,
  text,
  projectName,
}) => (
  <Container>
    {fluidGatsbyImage ? (
      <MainPicture background={fluidGatsbyImage.src} />
    ) : (
      <MainPicture background={image.childImageSharp.fixed.src} />
    )}
    <TextContainer>
      <TitleRow>
        <ProfileImage background={profilImage} />
        <TitleTexts>
          <P color="#5C239B" mobileSize="14px;">
            {title}
          </P>
          <P color="#00C0EE" weight="500" mobileSize="14px;">
            {projectName}
          </P>
        </TitleTexts>
      </TitleRow>

      <SmallP color="#000" weight="500">
        {text}
      </SmallP>
    </TextContainer>
  </Container>
)

const Container = styled.div`
  width: 250px;

  transition: width 0.3s;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  &:hover {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0);
  }

  @media only screen and (max-width: 970px) {
    width: 100%;
  }
`
const TitleRow = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
`
const TitleTexts = styled.div`
  display: flex;
  flex-direction: column;
`

const MainPicture = styled.div`
  width: 100%;
  height: 150px;
  background: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-position: center;
`

const MainPictureContainer = styled.div`
  width: 100%;
  height: 150px;
`

const ProfileImage = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-position: center;
  margin-right: 10px;
`

const TextContainer = styled.div`
  width: 100%;
  background: #fff;
  padding: 23px 13px;

  @media only screen and (max-width: 970px) {
    padding: 18px 8px;
  }

  h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    span {
      color: #00c0ee;
    }
  }
`

export default ProjectCard
