import React from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import CampusBackground from "../../images/immersionbackground.png"
import CampusBackgroundMobile from "../../images/mobileimmersion.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

import SmallP from "../fonts/smallp"
import H2 from "../fonts/h2"

const CampusSection = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => <Dots>{dots}</Dots>,
  }

  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            strapiLeCampus {
              id
              titre
              description
              slider {
                image {
                  childImageSharp {
                    fixed(width: 900, height: 900) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              legendeText
              legendeImage {
                childImageSharp {
                  fluid(maxWidth: 200, maxHeight: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `}
        render={retrieveDatas => {
          const sectionDatas = retrieveDatas
            ? retrieveDatas.strapiLeCampus
            : null
          return (
            <CampusContainer>
              <BackgroundElemMobile
                src={CampusBackgroundMobile}
                alt="Element graphique non essentiel"
              />
              <CampusContent>
                <SliderContainer>
                  <BackgroundElem
                    src={CampusBackground}
                    alt="Element graphique non essentiel"
                  />
                  <Slider {...settings}>
                    {sectionDatas &&
                      sectionDatas.slider.map(({ image }) => {
                        return (
                          <Slide background={image.childImageSharp.fixed.src} />
                        )
                      })}
                  </Slider>
                  <CaptionContainer>
                    <SmallP weight="500">
                      {sectionDatas && sectionDatas.legendeText}
                    </SmallP>
                    <Logo>
                      <Img
                        fluid={
                          sectionDatas &&
                          sectionDatas.legendeImage.childImageSharp.fluid
                        }
                      />
                    </Logo>
                  </CaptionContainer>
                </SliderContainer>
                <TextContainer>
                  <H2 mobileSize="25px">
                    <ReactMarkdown
                      source={sectionDatas ? sectionDatas.titre : ""}
                    />
                  </H2>
                  <Spacer />
                  <SmallP weight="500">
                    <ReactMarkdown
                      source={sectionDatas && sectionDatas.description}
                    />
                  </SmallP>
                </TextContainer>
              </CampusContent>
            </CampusContainer>
          )
        }}
      />
    </>
  )
}

const Dots = styled.div`
  right: 32px !important;
  bottom: 23px !important;
  width: auto !important;
  li {
    background: #f5f5f5;
    width: 11px !important;
    height: 11px !important;
    border-radius: 50px;
    button:before {
      display: none;
    }
    &.slick-active {
      background: #00c0ee;
    }
  }
  @media only screen and (max-width: 768px) {
    right: 50% !important;
    bottom: 18px !important;
    transform: translateX(50%);
  }
`

const Spacer = styled.div`
  height: 20px;
`

const BackgroundElem = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  right: -40px;
  top: -40px;
  max-width: none;
  z-index: -1;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const BackgroundElemMobile = styled.img`
  position: absolute;
  display: none;
  @media only screen and (max-width: 768px) {
    left: 0;
    top: 0;
    max-width: 100%;
    width: 90%;
    display: block;
    z-index: -1;
  }
`

const CampusContainer = styled.div`
  height: auto;
  padding: 30px 10% 30px 5%;
  position: relative;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`

const CampusContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const SliderContainer = styled.div`
  position: relative;
  width: 55%;
  height: auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 237px;
    margin-top: 40px;
  }
`

const Slide = styled.div`
  width: 45vw;
  height: 36vw;
  background: ${({ background }) => `url(${background})`};
  background-size: cover;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    height: 237px;
  }
`

const TextContainer = styled.div`
  width: 100%;
  padding-left: 70px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    padding: 0 10%;
    span {
      display: block;
    }
  }
`

const Logo = styled.div`
  width: 100px;
  padding-left: 10px;
`

const CaptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1%;
  z-index: 100;
  padding: 0 8vw;
`
export default CampusSection
